import React from "react";
import { Popover } from "react-tiny-popover";
import PropTypes from "prop-types";
import style from "./ColorAction.module.css";
import classNames from "classnames/bind";
let cx = classNames.bind(style);

function ColorAction({
  commandFn,
  colorHex,
  isSelected,
  popoverContent,
  noHover,
}) {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const onMouseEnter = () => {
    if (!popoverContent) return;
    setIsPopoverOpen(true);
  };
  const onMouseLeave = () => {
    if (!popoverContent) return;
    setIsPopoverOpen(false);
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={["bottom"]} // if you'd like, you can limit the positions
      padding={10} // adjust padding here!
      reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
      onClickOutside={() => setIsPopoverOpen(false)} // handle click events outside of the popover/target here!
      content={(
        { position, nudgedLeft, nudgedTop } // you can also provide a render function that injects some useful stuff!
      ) =>
        popoverContent ? (
          <div className={cx({ popover: true })}>{popoverContent}</div>
        ) : null
      }
    >
      <button
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={() => {
          commandFn && commandFn(colorHex);
        }}
        className={cx({
          "action-container": true,
          selected: isSelected,
          "no-hover": noHover,
        })}
      >
        <span
          className={cx({ action: true })}
          style={{ "--selectedBg": colorHex }}
        ></span>
      </button>
    </Popover>
  );
}

ColorAction.propTypes = {
  commandFn: PropTypes.func,
  colorHex: PropTypes.string,
  popoverContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default ColorAction;
