export const STROKE = "#000000";
export const FILL = "rgba(255, 255, 255, 0.0)";

export const SHAPES = {
  triangle: "triangle",
  rect: "rect",
  circle: "circle",
  type: "shapes",
};

export const DRAW = {
  type: "path",
};

export const CIRCLE = {
  type: "circle",
  radius: 20,
  left: 100,
  top: 100,
  fill: FILL,
  stroke: STROKE,
};

export const TRIANGLE = {
  type: "triangle",
  left: 100,
  top: 100,
  width: 40,
  height: 40,
  fill: FILL,
  stroke: STROKE,
};

export const RECTANGLE = {
  type: "rect",
  left: 100,
  top: 100,
  fill: FILL,
  stroke: STROKE,
  width: 40,
  height: 40,
  angle: 0,
};

export const TEXT = {
  type: "textbox",
  left: 100,
  top: 100,
  fontSize: 30,
  fontFamily: "Arial",
  fill: STROKE,
  editable: true,
};

export const STAR = {
  left: 100,
  top: 100,
  width: 40,
  height: 40,
  fill: FILL,
  stroke: STROKE,
};

export const HEART = {
  left: 100,
  top: 100,
  fill: FILL,
  stroke: STROKE,
};

export const PAW = {
  left: 100,
  top: 100,
  fill: FILL,
  stroke: STROKE,
};

export const EMOJI = {
  left: 100,
  top: 100,
  fill: FILL,
  stroke: STROKE,
};

export const SUN = {
  left: 100,
  top: 100,
  fill: FILL,
  stroke: STROKE,
};

export const emptyEditorFabricjs = {
  version: "4.5.1",
  objects: [],
  background: "white",
};
