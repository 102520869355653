import React, { useCallback, useEffect, useRef } from "react";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import style from "./PageEditor.module.css";
import { db, storage } from "../../services/firebase";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useAppContext } from "../../context";
import ButtonAction from "../../components/buttonAction/ButtonAction";
import {
  MdTextFields,
  MdBrush,
  MdStarBorder,
  MdImage,
  MdDelete,
  MdFormatAlignLeft,
  MdFormatAlignRight,
  MdSave,
  MdArrowBack,
  MdArrowForward,
  MdKeyboardArrowUp,
  MdFormatAlignCenter,
} from "react-icons/md";

import {
  BsCircle,
  BsHeart,
  BsSquare,
  BsStar,
  BsSun,
  BsTriangle,
  BsEmojiSmile,
} from "react-icons/bs";
import { IoMdPaw } from "react-icons/io";

import PreviewEditor from "../../components/previewEditor/PreviewEditor";

import classNames from "classnames/bind";
import ButtonActionDropDown from "../../components/buttonActionDropDown";

import ColorAction from "../../components/colorAction/ColorAction";
import fabricFactory from "../../fabricJS-factory";
import { DRAW, SHAPES, TEXT } from "../../fabricJS-defaultShapes";
import { debounce } from "lodash";
import { useKey } from "rooks";
import { useRecoilState } from "recoil";
import { bookState } from "../../features/BookAtom";
import { imagekitUpload } from "../../services/imageKit";
import Loader from "../../components/Loader/Loader";
import { getBookContent, getBookMetaData } from "../../services/bookLibrary";
import { getColorFromFabricShape } from "../../helpers";

const cx = classNames.bind(style);

const textAlignments = {
  left: "left",
  right: "right",
  center: "center",
  type: "textAlign",
};

const colors = {
  black: { label: "black", hex: "#000000" },
  white: { label: "white", hex: "#ffffff" },
  grey: { label: "grey", hex: "#A6A6A6" },
  darkBlue: { label: "dark blue", hex: "#002060" },
  blue: { label: "blue", hex: "#0070C0" },
  teal: { label: "teal", hex: "#00B0F0" },
  green: { label: "green", hex: "#00B050" },
  yellow: { label: "yellow", hex: "#FFFF00" },
  orange: { label: "orange", hex: "#ED7D31" },
  red: { label: "red", hex: "#FF0000" },
  pink: { label: "pink", hex: "#FF66FF" },
  purple: { label: "purple", hex: "#7030A0" },
  type: "colors",
};

const colorsList = Object.values(colors).filter((color) => color.hex);

const hexToColor = colorsList.reduce((obj, current) => {
  obj[current.hex] = current;
  return obj;
}, {});

const PAGES_LOADING = "PAGES_LOADING";
const PAGES_FETCHED = "PAGES_FETCHED";
const PAGES_SET_ACTIVE = "PAGES_SET_ACTIVE";
const TEXT_EDITING_TOGGLE = "TEXT_EDITING_TOGGLE";
const TEXT_SET_ALIGN = "TEXT_SET_ALIGN";
const ACTION_SET_ACTIVE = "ACTION_SET_ACTIVE";
const ACTION_DROP_DOWN_SET_ACTIVE = "ACTION_DROP_DOWN_SET_ACTIVE";
const COLOR_SET = "COLOR_SET";
const PAGE_UPDATE = "PAGE_UPDATE";

const initialState = {
  pages: Array(8).fill({
    content: '{"version":"4.5.1","objects":[]}',
  }),
  isLoading: true,
  activePageIndex: 0,
  activeAction: null,
  actionDropDowns: {
    textAlign: false,
    shapes: false,
    colors: false,
  },
  isTextEditing: false,
  textAlign: textAlignments.left,
  color: colors.black,
};

const debounceSave = debounce((bookId, pages) => {
  db.collection(`books/${bookId}/pages`)
    .get()
    .then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        // doc.data() is never undefined for query doc snapshots
        doc.ref.update(pages[doc.id]);
      });
    });
}, 1000);

const reducer = (state, action) => {
  //console.log({ action: action.type, payload: action.payload });

  if (action.type === PAGES_LOADING) {
    return {
      ...state,
      isLoading: action.payload,
    };
  }

  if (action.type === PAGES_FETCHED) {
    return {
      ...state,
      pages: action.payload.pages,
      isLoading: false,
    };
  }

  if (action.type === PAGES_SET_ACTIVE) {
    console.log({ reducer: action.payload.pageIndex });
    return {
      ...state,
      activePageIndex: action.payload.pageIndex,
    };
  }

  if (action.type === PAGE_UPDATE) {
    return {
      ...state,
      pages: state.pages.map((page, index) => {
        if (state.activePageIndex === index) {
          return { content: JSON.stringify(action.payload.page) };
        } else {
          return page;
        }
      }),
    };
  }

  if (action.type === ACTION_SET_ACTIVE) {
    return {
      ...state,
      activeAction: action.payload.action,
    };
  }

  if (action.type === ACTION_DROP_DOWN_SET_ACTIVE) {
    const actionDropDowns = { ...state.actionDropDowns };
    actionDropDowns[action.payload.name] = action.payload.isActive;
    //const isDynamicAction = action.payload.type === "dynamic";

    return {
      ...state,
      //activeAction: isDynamicAction ? state.activeAction : action.payload.name,
      actionDropDowns,
    };
  }

  if (action.type === TEXT_EDITING_TOGGLE) {
    return {
      ...state,
      isTextEditing: !state.isTextEditing,
    };
  }

  if (action.type === TEXT_SET_ALIGN) {
    return {
      ...state,
      textAlign: action.payload.textAlign,
    };
  }
  if (action.type === COLOR_SET) {
    return {
      ...state,
      color: action.payload.color,
    };
  }

  return state;
};

const PageEditor = () => {
  let navigate = useNavigate();
  const { state: context } = useAppContext();
  const [book, setBook] = useRecoilState(bookState);

  let { bookId } = useParams();
  const { selectedObjects, editor, onReady } = useFabricJSEditor();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const uploadImageInputRef = useRef(null);

  const editorRef = useRef(null);

  useEffect(() => {
    dispatch({ type: PAGES_LOADING, payload: true });

    async function getBookMeta() {
      const bookMeta = await getBookMetaData(bookId);
      const { title, authorID } = bookMeta;
      const isUserIsAlsoBookAuthor = context.user.uid === authorID;
      if (!isUserIsAlsoBookAuthor) {
        navigate("/login");
      }
      setBook({ title, authorID, bookID: bookId });
    }
    getBookMeta();

    async function getBook() {
      const pages = await getBookContent(bookId);
      dispatch({ type: PAGES_FETCHED, payload: { pages } });
    }
    getBook();

    return () => {
      setBook({});
    };
  }, []);

  // user select an object
  useEffect(() => {
    if (state.isLoading) return;
    if (selectedObjects.length > 0) {
      const lastActiveObject = selectedObjects[selectedObjects.length - 1];
      const isShapeOrPaintings =
        SHAPES[lastActiveObject.type] || lastActiveObject.isType(DRAW.type);
      if (isShapeOrPaintings) {
        const shapeColorHex = getColorFromFabricShape(lastActiveObject);
        const color = hexToColor[shapeColorHex];
        if (color) {
          dispatch({ type: COLOR_SET, payload: { color } });
        } else {
          console.warn("shape selected but we couldn't find it's color");
        }

        dispatch({
          type: ACTION_SET_ACTIVE,
          payload: { action: SHAPES.type },
        });
        return;
      }

      const isText = lastActiveObject.isType(TEXT.type);
      if (isText) {
        const textAlign = lastActiveObject.get("textAlign");
        const textColorHex = lastActiveObject.get("fill");
        const color = hexToColor[textColorHex];
        dispatch({ type: TEXT_SET_ALIGN, payload: { textAlign } });
        dispatch({ type: COLOR_SET, payload: { color } });
      }

      dispatch({
        type: ACTION_SET_ACTIVE,
        payload: { action: lastActiveObject.type },
      });
    } else {
      dispatch({ type: ACTION_SET_ACTIVE, payload: { action: null } });
    }

    editor?.canvas?.renderAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedObjects, state.isLoading]);

  useEffect(() => {
    if (state.isLoading) return;
    const handleTextEditing = () => {
      dispatch({ type: TEXT_EDITING_TOGGLE });
    };

    editor.canvas.on("text:editing:entered", handleTextEditing);
    editor.canvas.on("text:editing:exited", handleTextEditing);

    return () => {
      editor.canvas.off("text:editing:entered", handleTextEditing);
      editor.canvas.off("text:editing:exited", handleTextEditing);
    };
  }, [editor, state.isLoading]);

  useEffect(() => {
    if (!editor) return;
    const handleKeyUp = (envt) => {
      if (!state.isTextEditing && envt.key === "Backspace") {
        editor.deleteSelected();
      }
    };
    window.addEventListener("keyup", handleKeyUp);

    // cleanup this component
    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [state.isTextEditing, editor]);

  useEffect(() => {
    if (!state.isLoading && editor) {
      console.log("this effect run ", state.activePageIndex);
      const json = JSON.parse(state.pages[state.activePageIndex].content);
      editor.canvas.loadFromJSON(
        json,
        editor.canvas.renderAll.bind(editor.canvas)
      );
    }
  }, [state.isLoading, state.activePageIndex]);

  const prevJson = React.useRef(null);

  useEffect(() => {
    if (!editor?.canvas && state.isLoading) return;

    const editorChange = () => {
      //console.log("canvas render");
      const json = editor.canvas.toJSON();
      const jsonStr = JSON.stringify(json);
      const prevJsonStr = JSON.stringify(prevJson.current);
      if (jsonStr !== prevJsonStr) {
        dispatch({ type: PAGE_UPDATE, payload: { page: json } });
      }
      prevJson.current = json;
    };

    // const throttleChange = throttle(editorChange, 1000);
    const debounceChange = debounce(editorChange, 0);
    //editor.canvas.on("object:added", editorChange);
    // editor.canvas.on("object:removed", editorChange);
    //editor.canvas.on("object:modified", editorChange);
    editor.canvas.on("after:render", debounceChange);

    return () => {
      //editor.canvas.off("object:modified", editorChange);
      // editor.canvas.off("object:added", editorChange);
      // editor.canvas.off("object:removed", editorChange);
      editor.canvas.off("after:render", debounceChange);
    };
  }, [state.isLoading]);

  useEffect(() => {
    if (state.isLoading) return;
    dispatch({
      type: ACTION_DROP_DOWN_SET_ACTIVE,
      payload: {
        type: "dynamic",
        name: textAlignments.type,
        isActive: false,
      },
    });
  }, [state.textAlign, state.isLoading]);

  useEffect(() => {
    if (state.isLoading) return;
    dispatch({
      type: ACTION_DROP_DOWN_SET_ACTIVE,
      payload: {
        type: "dynamic",
        name: colors.type,
        isActive: false,
      },
    });
  }, [state?.color?.hex, state.isLoading]);

  useEffect(() => {
    if (!editor?.canvas && state.isLoading) return;

    if (state.activeAction === DRAW.type) {
      editor.canvas.isDrawingMode = true;
      editor.canvas.freeDrawingBrush.width = 5;
      editor.canvas.freeDrawingBrush.color = state.color.hex;
    } else {
      editor.canvas.isDrawingMode = false;
    }
  }, [state.isLoading, state.activeAction, editor?.canvas, state?.color?.hex]);

  const onImageChange = (e) => {
    const fileInput = e.target;
    const image = fileInput.files[0];
    if (!image) return;

    // upload image here
    dispatch({ type: PAGES_LOADING, payload: true });
    imagekitUpload(image, image.name, bookId, (url) => {
      fileInput.value = ""; // reset input for user uploading same image again

      onAddImage(url);
      dispatch({ type: PAGES_LOADING, payload: false });
    });
  };

  useEffect(() => {
    debounceSave(bookId, state.pages);
  }, [state.pages]);

  const changeTextAlign = (align = textAlignments.left) => {
    selectedObjects.forEach((obj) => {
      if (obj.type === TEXT.type) {
        obj.set("textAlign", align);
        if (align === textAlignments.left) {
          obj.set("direction", "ltr");
        } else if (align === textAlignments.right) {
          obj.set("direction", "rtl");
        }
      }
    });

    dispatch({ type: TEXT_SET_ALIGN, payload: { textAlign: align } });

    editor.canvas.renderAll();
  };

  const changeColor = (color) => {
    selectedObjects.forEach((obj) => {
      if (obj.type === TEXT.type) {
        obj.set("fill", color.hex);
      }
      if (SHAPES[obj.type] || obj.type === DRAW.type) {
        obj.set("stroke", color.hex);
      }
    });
    editor.canvas.renderAll();
    dispatch({ type: COLOR_SET, payload: { color } });
  };

  const onNextPage = useCallback(() => {
    const nextPage = (state.activePageIndex + 1) % state.pages.length;

    console.log({ page: state.activePageIndex, nextPage });
    dispatch({
      type: PAGES_SET_ACTIVE,
      payload: { pageIndex: nextPage },
    });
  }, [state.activePageIndex, state.pages.length]);

  const onPrevPage = useCallback(() => {
    const activePage =
      state.activePageIndex <= 0
        ? state.pages.length - 1
        : state.activePageIndex - 1;

    dispatch({
      type: PAGES_SET_ACTIVE,
      payload: { pageIndex: activePage },
    });
  }, [state.activePageIndex, state.pages.length]);

  useKey(["ArrowRight"], () => {
    onNextPage();
  });
  useKey(["ArrowLeft"], () => {
    onPrevPage();
  });

  const onDelete = () => {
    editor.deleteSelected();
  };

  const onAddImage = (url) => {
    fabricFactory.image(url).then((imgObj) => {
      editor.canvas.setActiveObject(imgObj);

      editor.canvas.add(imgObj);
      editor.canvas.sendBackwards(imgObj);
      editor.canvas.renderAll();
    });
  };

  const onAddObject = async (type) => {
    const object = await fabricFactory[type]();
    editor.canvas.setActiveObject(object);
    editor.canvas.add(object);
  };

  const onAddText = useCallback(() => {
    const object = fabricFactory.text();
    editor?.canvas.setActiveObject(object);
    editor?.canvas.add(object);

    object.enterEditing();
    object.selectAll();
  }, [editor?.canvas]);

  return (
    <main className={style.grid}>
      <Loader show={state.isLoading} />
      <section className={style["actions-container"]}>
        <div className={style.actions}>
          <div className={style["actions-static"]}>
            <ButtonAction
              icon={<MdTextFields />}
              isSelected={state.activeAction === TEXT.type}
              commandFn={onAddText}
              popoverContent={"Text"}
            ></ButtonAction>
            <ButtonActionDropDown
              isOpen={state.actionDropDowns.shapes}
              changeIsOpen={(isOpen) => {
                dispatch({
                  type: ACTION_SET_ACTIVE,
                  payload: {
                    action: isOpen ? SHAPES.type : state.activeAction,
                  },
                });
                dispatch({
                  type: ACTION_DROP_DOWN_SET_ACTIVE,
                  payload: {
                    type: "static",
                    name: SHAPES.type,
                    isActive: isOpen,
                  },
                });
              }}
              icon={() => {
                return (
                  <ButtonAction
                    icon={<MdStarBorder />}
                    isSelected={
                      state.actionDropDowns.shapes ||
                      state.activeAction === SHAPES.type
                    }
                    noHover
                    popoverContent={<span>Shape</span>}
                  ></ButtonAction>
                );
              }}
              popoverContent={<span>Shape</span>}
              dropDownContent={
                <>
                  <ButtonAction
                    icon={<BsCircle />}
                    commandFn={() => {
                      onAddObject("circle");
                      dispatch({
                        type: ACTION_DROP_DOWN_SET_ACTIVE,
                        payload: {
                          type: "static",
                          name: SHAPES.type,
                          isActive: false,
                        },
                      });
                    }}
                    popoverContent={<span>Circle</span>}
                  ></ButtonAction>
                  <ButtonAction
                    icon={<BsSquare />}
                    commandFn={() => {
                      onAddObject("rectangle");
                      dispatch({
                        type: ACTION_DROP_DOWN_SET_ACTIVE,
                        payload: {
                          type: "static",
                          name: SHAPES.type,
                          isActive: false,
                        },
                      });
                    }}
                    popoverContent={<span>Square</span>}
                  ></ButtonAction>
                  <ButtonAction
                    icon={<BsTriangle />}
                    commandFn={() => {
                      onAddObject("triangle");
                      dispatch({
                        type: ACTION_DROP_DOWN_SET_ACTIVE,
                        payload: {
                          type: "static",
                          name: SHAPES.type,
                          isActive: false,
                        },
                      });
                    }}
                    popoverContent={<span>Triangle</span>}
                  ></ButtonAction>
                  <ButtonAction
                    icon={<BsStar />}
                    commandFn={() => {
                      onAddObject("star");
                      dispatch({
                        type: ACTION_DROP_DOWN_SET_ACTIVE,
                        payload: {
                          type: "static",
                          name: SHAPES.type,
                          isActive: false,
                        },
                      });
                    }}
                    popoverContent={<span>Star</span>}
                  ></ButtonAction>
                  <ButtonAction
                    icon={<BsHeart />}
                    commandFn={() => {
                      onAddObject("heart");
                      dispatch({
                        type: ACTION_DROP_DOWN_SET_ACTIVE,
                        payload: {
                          type: "static",
                          name: SHAPES.type,
                          isActive: false,
                        },
                      });
                    }}
                    popoverContent={<span>Heart</span>}
                  ></ButtonAction>
                  <ButtonAction
                    icon={<BsEmojiSmile />}
                    commandFn={() => {
                      onAddObject("emoji");
                      dispatch({
                        type: ACTION_DROP_DOWN_SET_ACTIVE,
                        payload: {
                          type: "static",
                          name: SHAPES.type,
                          isActive: false,
                        },
                      });
                    }}
                    popoverContent={<span>Emoji</span>}
                  ></ButtonAction>
                </>
              }
            ></ButtonActionDropDown>
            <ButtonAction
              isSelected={state.activeAction === DRAW.type}
              icon={<MdBrush />}
              commandFn={() => {
                editor.canvas.discardActiveObject();
                setTimeout(() => {
                  dispatch({
                    type: ACTION_SET_ACTIVE,
                    payload: {
                      action:
                        state.activeAction === DRAW.type ? null : DRAW.type,
                    },
                  });
                });
              }}
              popoverContent={<span>Brush</span>}
            ></ButtonAction>
            <ButtonAction
              icon={<MdImage />}
              commandFn={() => {
                uploadImageInputRef.current.click();
              }}
              popoverContent={<span>Upload Image</span>}
            ></ButtonAction>
            <input
              className={style["upload-image-input"]}
              type="file"
              ref={uploadImageInputRef}
              onChange={(e) => {
                onImageChange(e);
              }}
              accept="image/png, image/jpeg"
            ></input>
            <ButtonAction
              icon={<MdDelete />}
              commandFn={onDelete}
              popoverContent={<span>Delete</span>}
            ></ButtonAction>
          </div>
          <div className={style.separator} />
          <div className={style["actions-dynamic"]}>
            <ButtonActionDropDown
              isOpen={state.actionDropDowns.textAlign}
              changeIsOpen={(isOpen) => {
                dispatch({
                  type: ACTION_DROP_DOWN_SET_ACTIVE,
                  payload: {
                    type: "dynamic",
                    name: textAlignments.type,
                    isActive: isOpen,
                  },
                });
              }}
              icon={() => {
                if (state.textAlign === textAlignments.left) {
                  return (
                    <ButtonAction
                      icon={<MdFormatAlignLeft />}
                      popoverContent="Align"
                      noHover
                    ></ButtonAction>
                  );
                }
                if (state.textAlign === textAlignments.center) {
                  return (
                    <ButtonAction
                      icon={<MdFormatAlignCenter />}
                      popoverContent="Align"
                      noHover
                    ></ButtonAction>
                  );
                }
                if (state.textAlign === textAlignments.right) {
                  return (
                    <ButtonAction
                      icon={<MdFormatAlignRight />}
                      popoverContent="Align"
                      noHover
                    ></ButtonAction>
                  );
                }
              }}
              dropDownContent={
                <>
                  <ButtonAction
                    icon={<MdFormatAlignLeft />}
                    commandFn={() => changeTextAlign(textAlignments.left)}
                    popoverContent={<span>Align left</span>}
                  ></ButtonAction>
                  <ButtonAction
                    icon={<MdFormatAlignCenter />}
                    commandFn={() => changeTextAlign(textAlignments.center)}
                    popoverContent={<span>Align center</span>}
                  ></ButtonAction>
                  <ButtonAction
                    icon={<MdFormatAlignRight />}
                    commandFn={() => changeTextAlign(textAlignments.right)}
                    popoverContent={<span>Align right</span>}
                  ></ButtonAction>
                </>
              }
            ></ButtonActionDropDown>
            <ButtonActionDropDown
              isOpen={state.actionDropDowns.colors}
              changeIsOpen={(isOpen) => {
                dispatch({
                  type: ACTION_DROP_DOWN_SET_ACTIVE,
                  payload: {
                    type: "dynamic",
                    name: colors.type,
                    isActive: isOpen,
                  },
                });
              }}
              icon={() => {
                return (
                  <ColorAction
                    colorHex={state.color.hex}
                    //commandFn={(color) => {}}
                    colorName={state.color.label}
                    popoverContent={state.color.label}
                    noHover
                  />
                );
              }}
              dropDownContent={
                <>
                  <div className={style["colors-container"]}>
                    {colorsList.map((color) => {
                      return (
                        <ColorAction
                          key={color.hex}
                          colorHex={color.hex}
                          commandFn={(colorHex) => {
                            const color = hexToColor[colorHex];
                            changeColor(color);
                          }}
                          popoverContent={color.label}
                        />
                      );
                    })}
                  </div>
                </>
              }
            ></ButtonActionDropDown>
          </div>
        </div>
      </section>
      <section className={style.editors}>
        <>
          <ButtonAction
            icon={<MdArrowBack />}
            commandFn={onPrevPage}
            popoverContent="Previous"
            float
          ></ButtonAction>
          <div className={style.editor} ref={editorRef}>
            <FabricJSCanvas className={style.canvas} onReady={onReady} />
          </div>

          <ButtonAction
            icon={<MdArrowForward />}
            commandFn={onNextPage}
            popoverContent="Next"
            float
          ></ButtonAction>
        </>
      </section>
      <section className={style["previews-container"]}>
        <div className={style["preview-drawer"]}>
          <div className={style["preview-arrow"]}>
            <MdKeyboardArrowUp></MdKeyboardArrowUp>
          </div>

          <h2 className={style["preview-title"]}>Preview</h2>
        </div>
        <div className={style.previews}>
          {Array.from({ length: 8 }).map((item, index) => {
            return (
              <div
                className={style["preview-container"]}
                key={index}
                onClick={() => {
                  dispatch({
                    type: PAGES_SET_ACTIVE,
                    payload: { pageIndex: index },
                  });
                }}
              >
                <div className={style["preview-number"]}>{index + 1}</div>
                <div
                  className={cx({
                    preview: true,
                    "preview-active": state.activePageIndex === index,
                  })}
                  key={"preview" + index}
                >
                  <PreviewEditor
                    content={state.pages[index]?.content}
                    id={index}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </main>
  );
};

export default PageEditor;
