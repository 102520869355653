import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ContextProvider } from "./context";
import { RecoilRoot } from "recoil";
import "./reset.module.css";
import { hotjar } from "react-hotjar";

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
hotjar.initialize(2812492, 6);
