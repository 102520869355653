import { emptyEditorFabricjs } from "../fabricJS-defaultShapes";
import { analytics, db, functions } from "./firebase";
import { downloadPdfFile } from "./download";

export const createBook = (authorID, callback) => {
  const bookName = prompt("what is your book name?");
  analytics.logEvent("book_action", {
    action_name: "create",
    user: authorID,
  });
  db.collection("books")
    .add({
      title: bookName,
      authorID,
      isDuplicate: false,
      creationDate: Date.now(),
      printCounter: 0,
    })
    .then((docRef) => {
      const content = JSON.stringify(emptyEditorFabricjs);
      //FIXME: find a better way to create a book
      docRef.collection("pages").doc("0").set({ content });
      docRef.collection("pages").doc("1").set({ content });
      docRef.collection("pages").doc("2").set({ content });
      docRef.collection("pages").doc("3").set({ content });
      docRef.collection("pages").doc("4").set({ content });
      docRef.collection("pages").doc("5").set({ content });
      docRef.collection("pages").doc("6").set({ content });
      docRef.collection("pages").doc("7").set({ content });
      console.log("Document written with ID: ", docRef.id);

      callback(docRef.id);
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
};

export const getBookMetaData = async (bookId) => {
  const doc = await db.doc(`books/${bookId}`).get();
  return doc.data();
};

export const deleteBook = async (bookId) => {
  const deleteBookFunction = functions.httpsCallable("deleteBook");
  try {
    const data = {
      bookId: bookId,
    };
    const res = await deleteBookFunction(data);
  } catch (error) {
    var code = error.code;
    var message = error.message;
    var details = error.details;
    console.log(error);
  }
};

export const printBook = async (
  bookId,
  printWaterMark = true,
  printGuideLines = false
) => {
  window.print();
  analytics.logEvent("book_action", {
    action_name: "print",
  });

  // const printBookFunction = functions.httpsCallable("printBook");
  // try {
  //   const data = {
  //     bookId,
  //     printWaterMark,
  //     printGuideLines,
  //   };
  //   const res = await printBookFunction(data);
  //   const pdf = res.data.file;
  //   const fileName = res.data.fileName;

  //   //downloadPdfFile(pdf, fileName);

  //   var byteCharacters = atob(pdf);
  //   var byteNumbers = new Array(byteCharacters.length);
  //   for (var i = 0; i < byteCharacters.length; i++) {
  //     byteNumbers[i] = byteCharacters.charCodeAt(i);
  //   }
  //   var byteArray = new Uint8Array(byteNumbers);
  //   var file = new Blob([byteArray], { type: "application/pdf;base64" });
  //   var fileURL = (window.URL || window.webkitURL).createObjectURL(file);
  //   window.open(fileURL, "_blank_");
  // } catch (error) {
  //   var code = error.code;
  //   var message = error.message;
  //   var details = error.details;
  //   console.log(error);
  // }
};

export const shareBook = async (bookId) => {
  //TODO: share book
};

// this method should be private method for internal bookLibrary usage only
const updateBookMetaData = async (bookId, field, newValue) => {
  let bookMetaData = await getBookMetaData(bookId);
  bookMetaData[`${field}`] = newValue;
  db.doc(`books/${bookId}`).set(bookMetaData);
};

export const updateBookTitle = async (bookId, title) => {
  await updateBookMetaData(bookId, "title", title);
};

export const updatePrintCounter = async (bookId) => {
  let bookMetaData = await getBookMetaData(bookId);
  const printCounter = bookMetaData.printCounter + 1 || 0;
  updateBookMetaData(bookId, "printCounter", printCounter);
  //TODO: print book
};

export const getBookContent = async (bookId) => {
  const querySnapshot = await db.collection(`books/${bookId}/pages`).get();
  const pages = querySnapshot.docs.map((doc) => doc.data());
  return pages;
};

export const duplicateBook = async (originalBookId, callback) => {
  const bookMetaData = await getBookMetaData(originalBookId);
  const bookContent = await getBookContent(originalBookId);
  analytics.logEvent("book_action", {
    action_name: "create",
    user: bookMetaData.authorID,
  });
  const newBook = await db.collection("books").add({
    title: `${bookMetaData.title} - copy`,
    authorID: bookMetaData.authorID,
    originalBookId: originalBookId,
    isDuplicate: true,
    creationDate: Date.now(),
    printCounter: 0,
  });
  newBook.collection("pages").doc("0").set({ content: bookContent[0].content });
  newBook.collection("pages").doc("1").set({ content: bookContent[1].content });
  newBook.collection("pages").doc("2").set({ content: bookContent[2].content });
  newBook.collection("pages").doc("3").set({ content: bookContent[3].content });
  newBook.collection("pages").doc("4").set({ content: bookContent[4].content });
  newBook.collection("pages").doc("5").set({ content: bookContent[5].content });
  newBook.collection("pages").doc("6").set({ content: bookContent[6].content });
  newBook.collection("pages").doc("7").set({ content: bookContent[7].content });

  return newBook.id;
};
