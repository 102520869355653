import React from "react";
import Header from "../header";
import style from "./Page.module.css";

function Page({ children }) {
  return (
    <div className={style.page}>
      <Header />
      {children}
    </div>
  );
}

export default Page;
