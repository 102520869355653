import React from "react";
import style from "./Loader.module.css";

const Loader = ({ show = false }) => {
  if (!show) return null;
  return (
    <div className={style.overlay}>
      <span className={style.loader}></span>
      <p className={style.text}>Please Wait...</p>
    </div>
  );
};

export default Loader;
