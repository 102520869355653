import React from "react";
import PropTypes from "prop-types";
import style from "./ButtonBase.module.css";
import classNames from "classnames/bind";

let cx = classNames.bind(style);

function ButtonBase({ commandFn, label, size, fullWidth }) {
  return (
    <button
      onClick={() => {
        commandFn && commandFn();
      }}
      className={cx({ button: true, [size]: !!size, "full-width": fullWidth })}
    >
      {label}
    </button>
  );
}

ButtonBase.propTypes = {
  commandFn: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default ButtonBase;
