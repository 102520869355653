// import React from "react";

import awesomeWatermark from "react-awesome-watermark";
import styled from "styled-components";

const StyledWatermark = styled(awesomeWatermark)`
  margin: 0 auto;
`;

const WaterMark = ({ element, show = false }) => {
  if (!show) return element;
  return (
    <StyledWatermark
      text="Itsyboox"
      style={{
        width: 280,
        height: 388,
      }}
      multiple
    >
      {element}
    </StyledWatermark>
  );
};

export default WaterMark;
