
import ImageKit from "imagekit-javascript"
import imageCompression from 'browser-image-compression';

var imagekit = new ImageKit({
    publicKey: "public_7s9aPpkzt6z6xvRpvi9ton0+vQU=",
    urlEndpoint: "https://ik.imagekit.io/oqsbbux3tix/",
    authenticationEndpoint: "https://us-central1-itsyboox.cloudfunctions.net/imagekitAuth",
    //authenticationEndpoint: "http://localhost:5001/itsyboox/us-central1/imagekitAuth",
});    


const imagekitUpload = (file, fileName, bookId, callback)  => {

  var options = {
    maxSizeMB: 0.6,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  }
  imageCompression(file, options)
    .then(function (compressedFile) {
      imagekit.upload({
        file: compressedFile,
        fileName: fileName,
        folder: `books/${bookId}`,
        tags: [bookId],
        extensions: [
            {
                name: "aws-auto-tagging",
                minConfidence: 80,
                maxTags: 10
            }
        ]
    }, function(err, result) {
        const url = imagekit.url({
            src: result.url,
            transformation: []
        })
        callback(url, err);
        // console.log(arguments);
        
    })
      //return uploadToServer(compressedFile); // write your own logic
    })
    .catch(function (error) {
      console.log(error.message);
    });







}


export  { imagekitUpload};