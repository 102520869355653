import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { SET_USER, useAppContext } from "../../context";
import { analytics, auth } from "../../services/firebase";
import ButtonBase from "../buttonBase";
import { MdPerson } from "react-icons/md";
import style from "./Header.module.css";
import {
  bookState,
  guideLinesToggle,
  loadingState,
  waterMarkToggle,
} from "../../features/BookAtom";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  createBook,
  updateBookTitle,
  printBook,
  updatePrintCounter,
} from "../../services/bookLibrary";
import { MdEdit } from "react-icons/md";
import origami from "../../assests/origami.png";

function Header() {
  let navigate = useNavigate();
  const location = useLocation();
  const { state: context, dispatch } = useAppContext();
  const { user, book } = context;
  const bookDetails = useRecoilValue(bookState);
  const showGuideLines = useRecoilValue(guideLinesToggle);
  const showWaterMark = useRecoilValue(waterMarkToggle);
  const [isLoading, setIsLoading] = useRecoilState(loadingState);
  const [isUserIsAlsoBookAuthor, setIsUserIsAlsoBookAuthor] = useState(false);
  const [bookName, setBookName] = useState("");

  useEffect(() => {
    if (!user?.uid || !bookDetails?.authorID) return;
    setIsUserIsAlsoBookAuthor(user.uid === bookDetails.authorID);
    // console.log(isUserIsAlsoBookAuthor);
  }, [bookDetails]);

  const signOut = () => {
    analytics.logEvent("user_action", {
      action_name: "sign_out",
      user: user.id,
    });
    auth()
      .signOut()
      .then(() => {
        dispatch({ type: SET_USER, payload: null });
      });
  };
  const forwardToLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (!bookName) return;
    const { bookID } = bookDetails;
    const updateBookName = async () => {
      await updateBookTitle(bookID, bookName);
    };
    updateBookName();
  }, [bookName]);

  const onEditBookName = () => {
    const newBookName = prompt("what is your book name?");
    // TODO: store the new book name in db by BookId
    setBookName(newBookName);
  };

  return (
    <header className={style.header}>
      <img alt="logo" src={origami} className={style.logo} />
      <h1 className={style.logoText}>
        Itsyboox<span className={style.beta}>(beta)</span>
      </h1>
      <div className={style.separator} />
      {bookDetails?.title && (
        <div className={style["book-title"]}>
          <span>{bookName || bookDetails?.title}</span>
          {location.pathname.includes("edit") && isUserIsAlsoBookAuthor && (
            <div
              className={style["book-edit-name-icon"]}
              onClick={onEditBookName}
            >
              <MdEdit />
            </div>
          )}
        </div>
      )}
      <div className={style.actions}>
        {user ? (
          <>
            {!location.pathname.includes("books") && (
              <>
                {" "}
                <Link to="/books">My Books</Link>
                <div className={style.separator} />
              </>
            )}
            {location.pathname.includes("edit") && (
              <>
                <ButtonBase
                  label="Preview"
                  commandFn={() => {
                    navigate(`/view/${bookDetails.bookID}`);
                  }}
                />
                <div className={style.separator} />
              </>
            )}
            {location.pathname.includes("view") && isUserIsAlsoBookAuthor && (
              <>
                <ButtonBase
                  label="Edit"
                  commandFn={() => {
                    navigate(`/edit/${bookDetails.bookID}`);
                  }}
                />
                <div className={style.separator} />
              </>
            )}
            {location.pathname.includes("view") && (
              <>
                <ButtonBase
                  label="Print"
                  commandFn={() => {
                    printBook(bookDetails.bookID);
                  }}
                />
                <div className={style.separator} />
              </>
            )}
            {location.pathname.includes("books") && (
              <>
                <ButtonBase
                  label="Create New Itsybook"
                  commandFn={() => {
                    createBook(context.user.uid, (bookId) =>
                      navigate(`/edit/${bookId}`)
                    );
                  }}
                />
                <div className={style.separator} />
              </>
            )}

            <div className={style.avatar}>
              {user.photoURL ? (
                <img
                  className={style["avatar-image"]}
                  src={user.photoURL}
                  alt=""
                />
              ) : (
                <MdPerson />
              )}
            </div>
            <div className={style.separator} />
            <ButtonBase label="Sign Out" commandFn={signOut} />
          </>
        ) : (
          <>
            {location.pathname.includes("view") && (
              <>
                <ButtonBase
                  label="Print"
                  commandFn={() => {
                    printBook(bookDetails.bookID);
                  }}
                />
                <div className={style.separator} />
              </>
            )}
            <ButtonBase label="Sign In" commandFn={forwardToLogin} />
          </>
        )}
      </div>
    </header>
  );
}

export default Header;
