import React from "react";
import { Popover } from "react-tiny-popover";
import PropTypes from "prop-types";
import style from "./ButtonActionDropDown.module.css";
import classNames from "classnames/bind";
let cx = classNames.bind(style);

function ButtonActionDropDown({
  icon: Icon,
  label,
  isOpen = false,
  dropDownContent,
  changeIsOpen,
}) {
  return (
    <Popover
      isOpen={isOpen}
      positions={["bottom"]} // if you'd like, you can limit the positions
      padding={10} // adjust padding here!
      reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
      onClickOutside={() => changeIsOpen(false)} // handle click events outside of the popover/target here!
      content={(
        { position, nudgedLeft, nudgedTop } // you can also provide a render function that injects some useful stuff!
      ) =>
        dropDownContent ? (
          <div className={cx({ popover: true })}>{dropDownContent}</div>
        ) : null
      }
    >
      <div
        role="button"
        onClick={() => {
          changeIsOpen(!isOpen);
        }}
        className={cx({ action: true, open: isOpen })}
      >
        {React.isValidElement(Icon) ? Icon : Icon()}
        {label}
      </div>
    </Popover>
  );
}

ButtonActionDropDown.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  dropDownContent: PropTypes.element.isRequired,
  changeIsOpen: PropTypes.func,
};

export default ButtonActionDropDown;
