import React, { useState } from "react";
import PropTypes from "prop-types";
import style from "./BookCard.module.css";
import classNames from "classnames/bind";
import bookImage from "../../assests/book-cover2.jpg";
import { FiMoreVertical } from "react-icons/fi";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";

import "./Custom.css";
import { useNavigate } from "react-router-dom";
import {
  duplicateBook,
  deleteBook,
  updateBookTitle,
} from "../../services/bookLibrary";

import { analytics } from "../../services/firebase";
import { useAppContext } from "../../context";

let cx = classNames.bind(style);

function BookCard({ title, originalBookId, setIsLoading }) {
  const navigate = useNavigate();
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(3);
  const { state: context, dispatch } = useAppContext();
  const { user } = context;

  const navigateToEditBook = (e) => {
    const isClickedOnDropDownContainer = e.nativeEvent.path.some(
      (element) => element?.dataset?.dropDown
    );
    if (isClickedOnDropDownContainer) return;

    navigate(`/edit/${originalBookId}`);
  };

  return (
    <div
      className={cx("book", { "drop-down-open": isOpen })}
      onClick={navigateToEditBook}
    >
      <img src={bookImage} />
      <div className={cx("book-body")}>
        <div>
          <h2 dir="auto">{title}</h2>
        </div>
        <div data-drop-down="true">
          <button {...buttonProps}>
            <FiMoreVertical />
          </button>
          <div className={`demo-menu ${isOpen ? "visible" : ""}`} role="menu">
            <div
              {...itemProps[0]}
              className="menu-item"
              onClick={async (e) => {
                e.stopPropagation();
                const newBookName = prompt("what is your book name?");
                if (!newBookName) return;
                await updateBookTitle(originalBookId, newBookName);
              }}
            >
              Rename
            </div>
            <div
              {...itemProps[1]}
              className="menu-item"
              onClick={async (e) => {
                e.stopPropagation();
                const newBookId = await duplicateBook(originalBookId);
                navigate(`/edit/${newBookId}`);
              }}
            >
              Duplicate
            </div>
            <div
              {...itemProps[2]}
              className="menu-item"
              onClick={async (e) => {
                e.stopPropagation();
                if (
                  window.confirm(
                    `Are you sure you want to delete "${title}"`
                  ) === true
                ) {
                  setIsLoading(true);
                  analytics.logEvent("book_action", {
                    action_name: "delete",
                    user: user.uid,
                  });
                  await deleteBook(originalBookId);
                  setIsLoading(false);
                }
              }}
            >
              Delete
            </div>
            {/* <div
              {...itemProps[3]}
              className="menu-item"
              onClick={async (e) => {
                e.stopPropagation();
                setIsLoading(true);
                await printBook(originalBookId);
                setIsLoading(false);
              }}
            >
              Print
            </div> */}
          </div>
        </div>
      </div>
      {/* </Link> */}
    </div>
  );
}

BookCard.propTypes = {
  title: PropTypes.string,
  originalBookId: PropTypes.string,
  setIsLoading: PropTypes.func,
};

export default BookCard;
