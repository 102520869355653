import firebase from "firebase";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  // authDomain: `${process.env.REACT_APP_PROJECTID}.firebaseapp.com`,
  authDomain: `editor.${process.env.REACT_APP_PROJECTID}.com`,
  databaseURL: `https://${process.env.REACT_APP_PROJECTID}-default-rtdb.europe-west1.firebasedatabase.app/`,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: `${process.env.REACT_APP_PROJECTID}.appspot.com`,
  appId: "1:471191254506:web:58296e57269c01fb35ee93",
  measurementId: "G-TS1HB6YFSN",
};
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth;
export const analytics = firebase.analytics();
export const functions = firebase.functions();

if (process.env.REACT_APP_EMULATOR === "true") {
  console.log("Using Emulator!!!!!");
  db.useEmulator("localhost", 8080);
  auth().useEmulator("http://localhost:9099/", { disableWarnings: true });
  functions.useEmulator("localhost", 5001);
}
