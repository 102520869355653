import { atom } from "recoil";

export const bookState = atom({
  key: "bookState", // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

export const loadingState = atom({
  key: "loadingState", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const waterMarkToggle = atom({
  key: "waterMarkToggle",
  default: false,
});

export const guideLinesToggle = atom({
  key: "guideLinesToggle",
  default: false,
});
