import React, { useEffect } from "react";
import { auth } from "./services/firebase";

import { Navigate, Route, Routes, useHistory } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import PageLogin from "./pages/login/PageLogin";
import PageEditor from "./pages/editor/PageEditor";
import { SET_USER, useAppContext } from "./context";
import PageBooks from "./pages/books/PageBooks";
import Page from "./components/Page";

import PageView from "./pages/view/PageView";
import PagePrint from "./pages/print/PagePrint";
import { hotjar } from "react-hotjar";

function App() {
  const { dispatch, state } = useAppContext();

  const [isInitialized, setIsInitialized] = React.useState(false);

  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch({ type: SET_USER, payload: user });
        hotjar.identify(user.uid, {
          userProperty: "value",
          playingForFun: "TNT",
        });
      }
      setIsInitialized(true);
      // setUser(user);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  if (!isInitialized) return null;
  return (
    <Router>
      {state?.user ? (
        <Routes>
          <Route path="*" element={<Navigate to="/books" />} />
          <Route
            exact
            path="/books"
            element={
              <PrivateRoute exact path="/books">
                <PageBooks />
              </PrivateRoute>
            }
          />
          <Route
            path="/view/:bookId"
            element={
              <PrivateRoute path="/view/:bookId">
                <Page>
                  <PageView></PageView>
                </Page>
              </PrivateRoute>
            }
          />
          <Route
            path="/edit/:bookId"
            element={
              <PrivateRoute>
                <Page>
                  <PageEditor />
                </Page>
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/print/:bookId/*"
            element={
              <Page>
                <PagePrint></PagePrint>
              </Page>
            }
          ></Route>
        </Routes>
      ) : (
        <Routes>
          <Route path="*" element={<Navigate to="/login" />} />
          <Route
            path="/login"
            element={<PageLogin />}
            // render={() =>
            //   !state?.user ? <PageLogin /> : <Navigate to="/books" />
            // }
          />
          <Route
            path="/view/:bookId"
            element={
              <Page>
                <PageView></PageView>
              </Page>
            }
          ></Route>
          <Route
            path="/print/:bookId/*"
            element={
              <Page>
                <PagePrint></PagePrint>
              </Page>
            }
          ></Route>
        </Routes>
      )}
    </Router>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRouteOld({ children, ...rest }) {
  const { state: context } = useAppContext();
  return (
    <Route
      {...rest}
      render={() =>
        context.user ? (
          children
        ) : (
          <Navigate
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
}

function PrivateRoute({ children }) {
  const { state: context } = useAppContext();
  return context.user ? children : <Navigate to="/login" />;
}

function AdminRoute({ children, ...rest }) {
  const { state: context } = useAppContext();
  console.log(context.user);

  return (
    <Route
      {...rest}
      render={
        () => (context.user.role === "admin" ? children : <div>Restricted</div>) //TODO: make RBAC working
      }
    />
  );
}

export default App;
