import React from "react";
import { Popover } from "react-tiny-popover";
import PropTypes from "prop-types";
import style from "./ButtonAction.module.css";
import classNames from "classnames/bind";
let cx = classNames.bind(style);

function ButtonAction({
  commandFn,
  icon: Icon,
  label,
  isSelected,
  popoverContent,
  float,
  noHover,
  buttonProps,
}) {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const onMouseEnter = () => {
    if (!popoverContent) return;
    setIsPopoverOpen(true);
  };
  const onMouseLeave = () => {
    if (!popoverContent) return;
    setIsPopoverOpen(false);
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={["bottom"]} // if you'd like, you can limit the positions
      padding={10} // adjust padding here!
      reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
      onClickOutside={() => setIsPopoverOpen(false)} // handle click events outside of the popover/target here!
      content={(
        { position, nudgedLeft, nudgedTop } // you can also provide a render function that injects some useful stuff!
      ) =>
        popoverContent ? (
          <div className={cx({ popover: true })}>{popoverContent}</div>
        ) : null
      }
    >
      <button
        type="button"
        {...buttonProps}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={() => {
          commandFn && commandFn();
        }}
        className={cx({
          action: true,
          selected: isSelected,
          float: float,
          "no-hover": noHover,
        })}
      >
        {Icon ? Icon : null}
        {label}
      </button>
    </Popover>
  );
}

ButtonAction.propTypes = {
  commandFn: PropTypes.func,
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  icon: PropTypes.element,
  popoverContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  buttonProps: PropTypes.any,
};

export default React.memo(ButtonAction);
