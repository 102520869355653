import React from "react";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import style from "./PreviewEditor.module.css";
import classNames from "classnames/bind";
import { useOnWindowResize } from "rooks";
const cx = classNames.bind(style);
// components
const PreviewEditor = ({
  content,
  id,
  isRound = true,
  insideFlipbook = false,
}) => {
  const { editor, onReady } = useFabricJSEditor();

  // sometimes fabricjs sets canvas setDimensions to 0
  //  then we set to it manually
  useOnWindowResize(() => {
    editor.canvas.setDimensions({ width: 262, height: 389 });
  }, insideFlipbook);

  React.useEffect(() => {
    if (editor && content) {
      const json = JSON.parse(content);
      editor.canvas.loadFromJSON(
        json,
        () => {
          editor.canvas.selection = false;
          editor.canvas.defaultCursor = "pointer";
          editor.canvas.hoverCursor = "pointer";
          editor.canvas.renderAll();
        },
        function (o, object) {
          object.set("selectable", false);
        }
      );
    }
  }, [editor, content]);

  return (
    <FabricJSCanvas
      className={cx({ canvas: true, round: isRound })}
      onReady={onReady}
    />
  );
};

export default React.memo(PreviewEditor);
