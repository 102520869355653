import React from "react";
import PreviewEditor from "../../components/previewEditor/PreviewEditor";
import style from "./Print.module.css";
// import { ImScissors } from "react-icons/im";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

const Print = (props) => {
  return (
    <div className={cx(style.grid, "sheet")}>
      {/* <div className={style["cut-icon"]}>
        <ImScissors />
      </div> */}
      {props.pages.map((page, index) => {
        return (
          <div
            key={index}
            className={cx(style.page, {
              front: index === 0,
              back: index === 7,
              [`page${index}`]: index !== 0 && index !== 7,
            })}
          >
            <PreviewEditor
              key={index}
              content={page.content}
              id={index}
              isRound={false}
            ></PreviewEditor>
          </div>
        );
      })}
    </div>
  );
};

export default Print;
