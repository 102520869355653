import React from "react";
const Context = React.createContext({ user: null, book: null });

export const SET_USER = "SET_USER";
export const SET_BOOK = "SET_BOOK";

function contextReducer(state, action) {
  switch (action.type) {
    case SET_USER: {
      return { ...state, user: action.payload };
    }
    case SET_BOOK: {
      return { ...state, book: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ContextProvider({ children }) {
  const [state, dispatch] = React.useReducer(contextReducer, {
    user: null,
    book: null,
  });
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

function useAppContext() {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a useAppContext");
  }
  return context;
}
export { useAppContext, ContextProvider };
