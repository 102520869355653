import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import PreviewEditor from "../../components/previewEditor/PreviewEditor";
import style from "./PageView.module.css";
import { db } from "../../services/firebase";
import classNames from "classnames/bind";
import { useRecoilState } from "recoil";
import {
  bookState,
  guideLinesToggle,
  loadingState,
  waterMarkToggle,
} from "../../features/BookAtom";
import Loader from "../../components/Loader/Loader";

import HTMLFlipBook from "react-pageflip";
import "./pageView.css";
import ButtonAction from "../../components/buttonAction/ButtonAction";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { useKey } from "rooks";
import { Print } from "../../components/print";

const cx = classNames.bind(style);

const Page = React.forwardRef((props, ref) => {
  const { content, index, showWaterMark } = props;

  return (
    <div className="flip-page" ref={ref}>
      <div className="flip-page-content">
        <PreviewEditor
          key={index}
          content={content}
          id={index}
          isRound={false}
          insideFlipbook
        ></PreviewEditor>

        {/* <WaterMark
          show={showWaterMark}
          element={
           
          }
        ></WaterMark> */}
      </div>
    </div>
  );
});

const DemoBook = function (props) {
  const book = useRef(null);

  useKey(["ArrowRight"], () => {
    book.current.pageFlip().flipNext();
  });
  useKey(["ArrowLeft"], () => {
    book.current.pageFlip().flipPrev();
  });

  return (
    <>
      <div className={cx("center")}>
        <ButtonAction
          icon={<MdArrowBack />}
          commandFn={() => book.current.pageFlip().flipPrev()}
          popoverContent="Previous"
          float
        ></ButtonAction>
      </div>
      <div className={cx("preview-container")}>
        <HTMLFlipBook
          width={265}
          height={388}
          autoSize={false}
          // minWidth={315}
          // maxWidth={1000}
          // minHeight={420}
          // maxHeight={1350}
          // size="stretch"
          maxShadowOpacity={0.5}
          showCover={true}
          mobileScrollSupport={false}
          onFlip={(...args) => {}}
          onUpdate={(arg) => {}}
          // onChangeOrientation={this.onChangeOrientation}
          // onChangeState={this.onChangeState}
          className="demo-book"
          ref={book}
        >
          {/* <PageCover>BOOK TITLE</PageCover>
        <Page number={1}>Lorem ipsum...</Page>
        <Page number={2}>Lorem ipsum...</Page>
        <PageCover>THE END</PageCover> */}
          {props.pages.map((page, index) => {
            return (
              <Page
                number={index}
                key={index}
                content={page.content}
                showWaterMark={props.showWaterMark}
              ></Page>
            );
          })}
        </HTMLFlipBook>

        {/* <div className={cx("preview-controls")}></div> */}
      </div>
      <div className={cx("center")}>
        <ButtonAction
          icon={<MdArrowForward />}
          commandFn={() => book.current.pageFlip().flipNext()}
          popoverContent="Next"
          float
        ></ButtonAction>
      </div>
    </>
  );
};

const PageView = () => {
  const [pages, setPages] = useState([]);
  //   let { search } = useLocation();
  let { bookId } = useParams();
  const [book, setBook] = useRecoilState(bookState);
  const [isLoading, setIsLoading] = useRecoilState(loadingState);
  const [showWaterMark, setShowWaterMark] = useState(waterMarkToggle);
  const [showGuideLines, setShowGuideLines] = useRecoilState(guideLinesToggle);

  // useEffect(() => {}, [showWaterMark]);

  useLayoutEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    db.doc(`books/${bookId}`)
      .get()
      .then((doc) => {
        const { title, authorID } = doc.data();
        setBook({ title, authorID, bookID: bookId });
      });

    const unsubscribe = db
      .collection(`books/${bookId}/pages`)
      .onSnapshot((querySnapshot) => {
        const pages = querySnapshot.docs.map((doc) => doc.data());
        setPages(pages);
        setTimeout(() => {
          setIsLoading(false);
          // window.print();
        }, 3000);
      });

    return () => {
      unsubscribe();
      setBook({});
    };
  }, []);

  return (
    <>
      <div className={cx("grid")}>
        <Loader show={isLoading} />

        <DemoBook pages={pages} showWaterMark={showWaterMark} />
      </div>
      <div className={`sheet ${cx("hidden-print")}`}>
        <Print pages={pages} />
      </div>
    </>
  );
};

export default PageView;
