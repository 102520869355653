import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import PreviewEditor from "../../components/previewEditor/PreviewEditor";
import style from "./PagePrint.module.css";
import { db } from "../../services/firebase";
import { ImScissors } from "react-icons/im";
import classNames from "classnames/bind";
import { useRecoilState } from "recoil";
import { bookState } from "../../features/BookAtom";
import WaterMark from "../../components/waterMark/WaterMark";
import _ from "lodash";

const cx = classNames.bind(style);

const PagePrint = () => {
  const [pages, setPages] = useState([]);
  //   let { search } = useLocation();
  let { bookId } = useParams();
  const [book, setBook] = useRecoilState(bookState);
  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries([...searchParams]);
  let waterMark = _.get(queryParams, "watermark", "true");
  waterMark.toLowerCase() === "false"
    ? (waterMark = false)
    : (waterMark = true);

  const guidelines = _.get(queryParams, "guidelines", "false");

  useEffect(() => {
    db.doc(`books/${bookId}`)
      .get()
      .then((doc) => {
        const { title, authorID } = doc.data();
        setBook({ title, authorID, bookID: bookId });
      });

    const unsubscribe = db
      .collection(`books/${bookId}/pages`)
      .onSnapshot((querySnapshot) => {
        const pages = querySnapshot.docs.map((doc) => doc.data());
        setPages(pages);
      });

    return () => {
      unsubscribe();
      setBook({});
    };
  }, []);

  return (
    <div className={cx(style.grid, "sheet")}>
      <div className={style["cut-icon"]}>
        <ImScissors />
      </div>
      {pages.map((page, index) => {
        return (
          <div
            className={cx(style.page, {
              front: index === 0,
              back: index === 7,
              [`page${index}`]: index !== 0 && index !== 7,
            })}
          >
            <WaterMark
              show={waterMark}
              element={
                <PreviewEditor
                  key={index}
                  content={page.content}
                  id={index}
                  isRound={false}
                ></PreviewEditor>
              }
            ></WaterMark>
          </div>
        );
      })}
    </div>
  );
};

export default PagePrint;
