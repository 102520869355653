import { fabric } from "fabric";
import {
  CIRCLE,
  RECTANGLE,
  STAR,
  TEXT,
  TRIANGLE,
  HEART,
  SUN,
  PAW,
  EMOJI,
} from "./fabricJS-defaultShapes";
import star from "./assests/editor-shapes/star-outline.svg";
import heart from "./assests/editor-shapes/heart.svg";
import sun from "./assests/editor-shapes/sun.svg";
import emoji from "./assests/editor-shapes/emoji.svg";
import paw from "./assests/editor-shapes/paw.svg";

const fabricFactory = {
  image: async (url) => {
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(url, function (imgObj) {
        imgObj.scaleToHeight(100);
        imgObj.scaleToWidth(100);
        resolve(imgObj);
      });
    });
  },
  text: () => new fabric.Textbox("Text", { ...TEXT }),
  circle: () =>
    new fabric.Circle({
      ...CIRCLE,
    }),
  triangle: () =>
    new fabric.Triangle({
      ...TRIANGLE,
    }),
  rectangle: () =>
    new fabric.Rect({
      ...RECTANGLE,
    }),
  star: async () => {
    return new Promise((resolve, reject) => {
      new fabric.loadSVGFromURL(star, (obj, options) => {
        const svgObj = fabric.util.groupSVGElements(obj, options);
        svgObj.set({ ...STAR });
        resolve(svgObj);
      });
    });
  },
  heart: async () => {
    return new Promise((resolve, reject) => {
      new fabric.loadSVGFromURL(heart, (obj, options) => {
        const svgObj = fabric.util.groupSVGElements(obj, options);
        svgObj.set({ ...HEART });
        resolve(svgObj);
      });
    });
  },
  sun: async () => {
    return new Promise((resolve, reject) => {
      new fabric.loadSVGFromURL(sun, (obj, options) => {
        const svgObj = fabric.util.groupSVGElements(obj, options);
        svgObj.set({ ...SUN });
        resolve(svgObj);
      });
    });
  },
  paw: async () => {
    return new Promise((resolve, reject) => {
      new fabric.loadSVGFromURL(paw, (obj, options) => {
        const svgObj = fabric.util.groupSVGElements(obj, options);
        svgObj.set({ ...PAW });
        resolve(svgObj);
      });
    });
  },
  emoji: async () => {
    return new Promise((resolve, reject) => {
      new fabric.loadSVGFromURL(emoji, (obj, options) => {
        const svgObj = fabric.util.groupSVGElements(obj, options);
        svgObj.set({ ...EMOJI });
        resolve(svgObj);
      });
    });
  },
};

export default fabricFactory;
