import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import { useAppContext } from "../../context";
import { db } from "../../services/firebase";
import style from "./PageBooks.module.css";
import classNames from "classnames/bind";
import BookCard from "../../components/bookCard/BookCard";
import Loader from "../../components/Loader/Loader";

const cx = classNames.bind(style);

function PageBooks() {
  const { state: context } = useAppContext();
  const [books, setBooks] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // use recoil for

  useEffect(() => {
    const unsubscribe = db
      .collection(`books`)
      .where("authorID", "==", context.user.uid)
      .onSnapshot((querySnapshot) => {
        const books = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          const book = { ...doc.data(), id: doc.id };
          books.push(book);
        });
        setBooks(books);
        setIsLoading(false);
      });

    return unsubscribe;
  }, [context.user.uid]);

  return (
    <>
      <Loader show={isLoading} />
      <Page>
        <div>
          <div className={cx("books")}>
            {books.map((book) => (
              <BookCard
                key={book.id}
                title={book.title}
                originalBookId={book.id}
                setIsLoading={setIsLoading}
              />
            ))}
          </div>
        </div>
      </Page>
    </>
  );
}

export default PageBooks;
